import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { authInitialized, isAuthenticated } from '../components/Auth'

import { type IStaticMethods } from "preline/preline";
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

const routes: Array<RouteRecordRaw> = [
  { path: '/', name: 'Accueil', component: HomeView, meta: { public: true, hideNav: true, hideFooter: true } },
  { path: '/les-jeux-du-moment', name: 'Les jeux du moment', component: () => import('../views/GalleryView.vue'), meta: { public: true, showInNavBar: true } },
  { path: '/solution-cle-en-main', name: 'Solution clé en main', component: () => import('../views/USPView.vue'), meta: { public: true, showInNavBar: true } },
  { path: '/etudes-de-cas', name: 'Études de cas', component: () => import('../views/CaseStudiesView.vue'), meta: { public: true, showInNavBar: true } },
  { path: '/contactez-nous', name: 'Contactez-nous', component: () => import('../views/ContactView.vue'), meta: { public: true, showInNavBar: true } },
  { path: '/identification', name: 'Connectez-vous', component: () => import('../views/LoginView.vue'), meta: { public: true, hideNav: true, hideFooter: true } },
  { path: '/connexion-reussie', name: 'Connexion réussie', component: () => import('../views/CompleteSigninView.vue'), meta: { public: true } },
  { path: '/mon-compte', name: 'Votre profil', component: () => import('../views/AccountView.vue'), meta: { public: false, showInUserPanel: true } },
  { path: '/tableau-de-bord', name: 'Vos jeux concours', component: () => import('../views/AppView.vue'), meta: { public: false, showInUserPanel: true, showInNavBar: true } },
  
  // Pages légales et informations
  { path: '/mentions-legales', name: 'Mentions légales', component: () => import('../views/LegalNoticeView.vue'), meta: { public: true, showInFooter: true } },
  { path: '/conditions-generales', name: 'Conditions générales', component: () => import('../views/TermsAndConditionsView.vue'), meta: { public: true, showInFooter: true } },
  { path: '/vie-privee', name: 'Politique de vie privée', component: () => import('../views/PrivacyPolicyView.vue'), meta: { public: true, showInFooter: true } },
  { path: '/faq', name: 'FAQ', component: () => import('../views/FaqView.vue'), meta: { public: true, showInFooter: true } },

  { path: '/404', name: 'Page introuvable', component: () => import('../views/NotFoundView.vue'), meta: { public: true } },
  { path: '/:catchAll(.*)', redirect: '/404' } // Redirection vers la page 404 pour les routes inconnues
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  await authInitialized() // Attend la résolution de l'état d'authentification
  if (!to.meta.public && !isAuthenticated()) {
    next('/identification')
  } else {
    next()
  }
})

router.afterEach((to, from, failure) => {
  if (!failure) {
    setTimeout(() => {
      window.HSStaticMethods.autoInit();
    }, 100)
  }
})

export default router