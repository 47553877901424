<template>
    <div class="relative">
      <Menu as="div" class="relative">
        <template #default>
          <MenuButton
            v-if="isUserAuthenticated"
            class="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
          >
            <img class="h-8 w-8 rounded-full" :src="userProfileImage" alt="User Profile" />
            <span class="sr-only">Open user menu</span>
          </MenuButton>
          <router-link
            v-else
            to="/identification"
            class="p-1 text-gray-400 hover:text-white focus:outline-none"
          >
            <UserCircleIcon class="h-6 w-6" aria-hidden="true" />
          </router-link>
          <MenuItems
            v-if="isUserAuthenticated"
            class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItem v-slot="{ active }">
                <router-link
                    to="/mon-compte"
                    :class="[active ? 'bg-gray-100' : '', 'block w-full px-4 py-2 text-sm text-gray-700 text-left']"
                >
                    Votre profil
                </router-link>
            </MenuItem>
            <MenuItem v-slot="{ active }">
                <router-link
                    to="/tableau-de-bord"
                    :class="[active ? 'bg-gray-100' : '', 'block w-full px-4 py-2 text-sm text-gray-700 text-left']"
                >
                    Tableau de bord
                </router-link>
            </MenuItem>
            <MenuItem v-slot="{ active }">
                <button
                    @click="logout"
                    :class="[active ? 'bg-gray-100' : '', 'block w-full px-4 py-2 text-sm text-gray-700 text-left']"
                >
                    Déconnexion
                </button>
            </MenuItem>
          </MenuItems>
        </template>
      </Menu>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue'
  import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
  import { useRouter } from 'vue-router'
  import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
  import { UserCircleIcon } from '@heroicons/vue/24/solid';
  
  const router = useRouter()
  const auth = getAuth()
  const isUserAuthenticated = ref(false)
  const userProfileImage = ref('https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=facearea&facepad=2&w=256&h=256&q=80')
  
  const logout = () => {
    signOut(auth)
      .then(() => router.push('/'))
      .catch(error => console.error('Error logging out:', error))
  }
  
  // Écoute les changements d’état de connexion
  onMounted(() => {
    onAuthStateChanged(auth, user => {
      isUserAuthenticated.value = !!user
      if (user && user.photoURL) {
        userProfileImage.value = user.photoURL // Si Firebase contient une image de profil utilisateur
      }
    })
  })
  </script>
  
  <style scoped>
  /* Styles supplémentaires si besoin */
  </style>