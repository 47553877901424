import './assets/css/tailwind.css';
import 'preline/preline';
import './assets/js/darkmode.js';
import { firebaseApp } from '././firebaseConfig';
import { onAuthChange } from '././components/Auth' // Import de la fonction d'écoute de l'authentification
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { VueFire, VueFireAuth } from 'vuefire';
import { ref } from 'vue'

const app = createApp(App);

export const isUserAuthenticated = ref(false) // Variable réactive globale

// Met à jour `isUserAuthenticated` en fonction des changements d'état d'authentification
onAuthChange((isLoggedIn) => {
  isUserAuthenticated.value = isLoggedIn
})

// Utilisation de VueFire avec Auth et Firestore pour la liaison de données
app.use(VueFire, {
  firebaseApp,
  modules: [] // Auth activé, Firestore géré automatiquement
});

app.use(router);
app.mount('#app');