<template>
    <footer class="bg-gray-800 text-gray-300 text-center p-4">
      <div class="container mx-auto text-center">
        <p>&copy; 2024 Fabrice Montfort. Tous droits réservés.</p>
        <div v-if="footerRoutes.length" class="mt-2 space-x-4 text-xs capitalize text-gray-400">
          <router-link
            v-for="route in footerRoutes"
            :key="route.path"
            :to="route.path"
            class="hover:text-gray-200"
          >
            {{ route.name }}
          </router-link>
        </div>
      </div>
    </footer>
  </template>
  
  <script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { isUserAuthenticated } from '../main' // Import de la variable réactive

const router = useRouter()

// Filtrer les routes visibles pour le Footer
const footerRoutes = computed(() =>
  router.options.routes.filter(route => {
    return route.meta && route.meta.showInFooter && (route.meta.public || isUserAuthenticated.value)
  })
)
</script>
  
  <style scoped>
  /* Styles du footer */
  footer {
    font-size: 0.875rem;
  }
  .text-xs {
    font-size: 0.75rem;
  }
  </style>