<template>
    <header class="flex flex-wrap sm:justify-start sm:flex-nowrap w-full text-sm py-3 bg-gray-800 text-gray-300">
        <nav class="max-w-[85rem] w-full mx-auto px-4 flex flex-wrap basis-full items-center justify-between">
            <!-- Branding -->
            <a class="flex-1 justify-center sm:order-1 flex-none text-xl font-semibold focus:outline-none focus:opacity-80" href="#">
                <span class="inline-flex items-center gap-x-2 text-xl font-semibold mt-2">
                    <img class="w-10 h-auto" src="../assets/images/logo.png" alt="Logo">
                    <span class="hidden sm:inline">Création de jeu concours</span>
                </span>
            </a>
            <div class="sm:order-3 flex items-center gap-x-2">
                <!-- Menu collapse -->
                <button type="button" class="order-1 sm:hidden hs-collapse-toggle relative size-7 flex justify-center items-center gap-x-2 rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none" id="hs-navbar-alignment-collapse" aria-expanded="false" aria-controls="hs-navbar-alignment" aria-label="Toggle navigation" data-hs-collapse="#hs-navbar-alignment">
                    <Bars3Icon class="hs-collapse-open:hidden shrink-0 size-4" aria-hidden="true" />
                    <XMarkIcon class="hs-collapse-open:block hidden shrink-0 size-4" aria-hidden="true" />
                    <span class="sr-only">Toggle</span>
                </button>
                <div class="py-2 px-3 inline-flex items-center gap-x-2">
                    <!-- Notifications -->
                    <button type="button" class="p-1 text-gray-400 hover:text-white focus:outline-none px-4">
                        <BellIcon class="h-6 w-6" aria-hidden="true" />
                        <span class="sr-only">View notifications</span>
                    </button>
                    
                    <!-- Dark mode -->
                    <button type="button" @click="toggleDarkMode" class="p-1 text-gray-400 hover:text-white focus:outline-none pr-4" aria-label="Toggle dark mode">
                        <component :is="isDarkMode ? SunIcon : MoonIcon" class="h-6 w-6" aria-hidden="true" />
                    </button>
                    
                    <!-- Profile & UserPanel -->
                    <UserPanel />
                </div>
            </div>
            <div id="hs-navbar-alignment" class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:grow-0 sm:basis-auto sm:block sm:order-2" aria-labelledby="hs-navbar-alignment-collapse">
                <div class="flex flex-col gap-5 mt-5 sm:flex-row sm:items-center sm:mt-0 sm:ps-5">
                    <!-- Links -->
                    <router-link
                        v-for="route in navBarRoutes"
                        :key="route.name"
                        :to="route.path"
                        class="font-medium text-gray-400 hover:text-white focus:outline-none"
                    >
                    {{ route.name }}
                    </router-link>
                </div>
            </div>
        </nav>
    </header>
</template>
  
<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon, MoonIcon, SunIcon } from '@heroicons/vue/24/outline'
import UserPanel from './UserPanel.vue'
import { computed, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { isUserAuthenticated } from '../main'

// Gestion du mode sombre
const isDarkMode = ref(false)

function toggleDarkMode() {
    const html = document.documentElement
    isDarkMode.value = !isDarkMode.value

    if (isDarkMode.value) {
        html.classList.add('dark')
        localStorage.setItem('hs_theme', 'dark')
    } else {
        html.classList.remove('dark')
        localStorage.setItem('hs_theme', 'light')
    }
}

onMounted(() => {
    isDarkMode.value = localStorage.getItem('hs_theme') === 'dark'
})

// Filtrage des routes pour la navigation
const router = useRouter()
const navBarRoutes = computed(() =>
    router.options.routes.filter(route => route.meta?.showInNavBar && (route.meta.public || isUserAuthenticated.value))
)
</script>