<template>
<!-- ========== MAIN CONTENT ========== -->
<main id="content" class="relative max-w-3xl px-4 sm:px-6 lg:px-8 flex flex-col justify-center sm:items-center mx-auto size-full before:absolute before:top-0 before:start-1/2 before:bg-[url('https://preline.co/assets/svg/examples-dark/squared-bg-element.svg')] before:bg-no-repeat before:bg-top before:size-full before:-z-[1] before:transform before:-translate-x-1/2">
  <div class="text-center py-8 px-4 sm:px-6 lg:px-8">
    <img class="w-24 h-auto mx-auto" src="../assets/images/logo.png" alt="Logo">
    <h1 class="text-2xl text-white sm:text-4xl">
      Soyez prévenu(e) en avant-première
    </h1>
    <h2 class="mt-1 sm:mt-3 text-4xl font-bold text-white sm:text-6xl">
      <span class="bg-clip-text bg-gradient-to-tr from-blue-600 to-purple-400 text-transparent">Création de jeu concours</span>
    </h2>

    <form id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate="">
      <input type="hidden" name="tags" value="2767143">
      <div class="mt-8 space-y-4">
        <div>
          <label for="hs-cover-with-gradient-form-name-1" class="sr-only">Prénom</label>
          <div class="relative">
            <input type="text" name="FNAME" class="text py-3 ps-11 pe-4 block w-full bg-white/10 border-white/20 text-white placeholder:text-white rounded-lg text-sm focus:border-white/30 focus:ring-white/30 sm:p-4 sm:ps-11" placeholder="Prénom" id="mce-FNAME" value="">
            <!--<input type="text" id="hs-cover-with-gradient-form-name-1" class="py-3 ps-11 pe-4 block w-full bg-white/10 border-white/20 text-white placeholder:text-white rounded-lg text-sm focus:border-white/30 focus:ring-white/30 sm:p-4 sm:ps-11" placeholder="Nom complet">-->
            <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
              <svg class="shrink-0 size-4 text-gray-400 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/></svg>
            </div>
          </div>
        </div>

        <div>
          <label for="hs-cover-with-gradient-form-name-1" class="sr-only">Nom</label>
          <div class="relative">
            <input type="text" name="LNAME" class="text py-3 ps-11 pe-4 block w-full bg-white/10 border-white/20 text-white placeholder:text-white rounded-lg text-sm focus:border-white/30 focus:ring-white/30 sm:p-4 sm:ps-11" placeholder="Nom" id="mce-LNAME" value="">
            <!--<input type="text" id="hs-cover-with-gradient-form-name-1" class="py-3 ps-11 pe-4 block w-full bg-white/10 border-white/20 text-white placeholder:text-white rounded-lg text-sm focus:border-white/30 focus:ring-white/30 sm:p-4 sm:ps-11" placeholder="Nom complet">-->
            <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
              <svg class="shrink-0 size-4 text-gray-400 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/></svg>
            </div>
          </div>
        </div>

        <div>
          <label for="hs-cover-with-gradient-form-email-1" class="sr-only">Adresse email</label>
          <div class="relative">
            <input type="email" name="EMAIL" class="required email py-3 ps-11 pe-4 block w-full bg-white/10 border-white/20 text-white placeholder:text-white rounded-lg text-sm focus:border-white/30 focus:ring-white/30 sm:p-4 sm:ps-11" placeholder="Adresse email" id="mce-EMAIL" required="" value="">
            <!--<input type="email" id="hs-cover-with-gradient-form-email-1" class="py-3 ps-11 pe-4 block w-full bg-white/10 border-white/20 text-white placeholder:text-white rounded-lg text-sm focus:border-white/30 focus:ring-white/30 sm:p-4 sm:ps-11" placeholder="Adresse email">-->
            <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
              <svg class="shrink-0 size-4 text-gray-400 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg>
            </div>
          </div>
        </div>

        <div aria-hidden="true" style="position: absolute; left: -5000px;">
          /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
          <input type="text" name="b_5c8bcb2f33bb4e5a25f462ab5_0c2e144a99" tabindex="-1" value="">
        </div>

        <div class="grid">
          <button type="submit" name="subscribe" id="mc-embedded-subscribe" value="Subscribe" class="button sm:p-4 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-white/10 text-white hover:bg-white/20 focus:outline-none focus:bg-white/20 disabled:opacity-50 disabled:pointer-events-none">
            Inscrivez-vous sur la liste d'attente
            <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6"/></svg>
          </button>
        </div>

        <div class="flex justify-center items-center">
          <img class="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-light.svg" alt="Intuit Mailchimp" style="width: 220px; height: 40px; padding: 2px 0;">
        </div>
      </div>
    </form>
  </div>
</main>
<!-- ========== END MAIN CONTENT ========== -->

<!-- ========== FOOTER ========== -->
<footer class="relative text-center py-5">
  <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
    <p class="text-sm text-white/50">&copy; 2024 Fabrice Montfort. Tous droits réservés.</p>
  </div>
</footer>
<!-- ========== END FOOTER ========== -->
</template>

<script setup>

import { ref, onMounted } from 'vue'


onMounted(() => {
  document.addEventListener("DOMContentLoaded", function () {
    const form = document.getElementById("mc-embedded-subscribe-form");
    if (form) {
      document.getElementById("mc-embedded-subscribe-form").addEventListener("submit", function(e) {
        e.preventDefault();
        // Collecte des données du formulaire
        const formData = new FormData(this);
        // Remplacez l'URL par celle de Mailchimp
        fetch("https://gmail.us9.list-manage.com/subscribe/post?u=5c8bcb2f33bb4e5a25f462ab5&amp;id=0c2e144a99&amp;f_id=0044d7e1f0", {
          method: "POST",
          body: formData,
          mode: "no-cors"
        }).then(() => {
          // Redirection personnalisée après inscription réussie
          window.location.href = "https://votre-site.fr/merci";
        }).catch((error) => console.error("Erreur:", error));
      });
    }
  });
});

</script>