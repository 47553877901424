// Auth.ts
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const auth = getAuth()
let isAuthInitialized = false

export function isAuthenticated(): boolean {
  return !!auth.currentUser
}

export function onAuthChange(callback: (isLoggedIn: boolean) => void) {
  onAuthStateChanged(auth, (user) => {
    isAuthInitialized = true
    callback(!!user)
  })
}

export function authInitialized(): Promise<boolean> {
  return new Promise(resolve => {
    onAuthStateChanged(auth, () => {
      isAuthInitialized = true
      resolve(!!auth.currentUser)
    })
  })
}

export function performLogout() {
  auth.signOut()
}