<template>
  <div class="flex flex-col min-h-screen">
    <!-- Utiliser NavBar et AppFooter pour toutes les pages sauf l'accueil -->
    <template v-if="!isHomePage">
      <NavBar />
      <main class="flex-grow bg-white dark:bg-gray-900 dark:text-white">
        <router-view />
      </main>
      <AppFooter />
    </template>

    <!-- Utiliser un template spécifique pour l'accueil -->
    <template v-else>
      <main class="flex-grow bg-gray-900 text-white">
        <router-view />
      </main>
    </template>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import NavBar from './components/NavBar.vue'
import AppFooter from './components/AppFooter.vue'
// import computed
import { computed } from 'vue'

// Vérifie si on est sur la page d'accueil
const route = useRoute()
const isHomePage = computed(() => route.path === '/')
</script>

<style scoped>
/* Style principal de la page */
html, body, #app {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}
</style>